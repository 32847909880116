/* eslint-disable @typescript-eslint/no-unused-vars */
import { IconButton, Switch } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import ConfirmDeleteModal from "components/ConfirmDeleteModal"; // Assuming ConfirmDeleteModal is correctly imported
import GKClientDataGrid from "components/GKClientDataGrid";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import toast from "react-hot-toast";
import { MdDelete } from "react-icons/md";
import accessBoardService from "services/accessBoard";
import axiosInstance from "utils/axiosInstance";
import { QueryKeys } from "utils/queryKey";

function AdminAccessBoard() {
  const {
    state: { currentUser },
  } = useAppContext();

  const queryClient = useQueryClient();
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(null);

  const {
    data: accessList,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [QueryKeys.ACCESS_LIST],
    queryFn: () => axiosInstance.get("/user/all_user/"),
  });

  const { mutate: deleteClient } = useMutation({
    mutationKey: ["DELETE_CLIENT"],
    mutationFn: (clientId) =>
      axiosInstance.delete(`/super_admin/${clientId}/delete-user/`),
    onSuccess: (response) => {
      refetch();
      toast.success(response?.data?.message || "Update Broker Successfully");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const columns: GridColDef[] = [
    {
      headerName: "User Name",
      flex: 1,
      field: "name",
    },
    {
      headerName: "User Phone",
      flex: 1,
      field: "phone",
    },
    {
      headerName: "User Email",
      flex: 1,
      field: "email",
    },
    {
      headerName: "Is Active",
      flex: 1,
      field: "isActive",
      renderCell: (item) => (item?.row?.isActive ? "Yes" : "No"),
    },
    {
      headerName: "Is Verified",
      flex: 1,
      field: "isVerified",
      renderCell: (item) => (item?.row?.isVerified ? "Yes" : "No"),
    },
    {
      headerName: "User Role",
      flex: 1,
      field: "userRole",
    },
    {
      headerName: "Grant Access",
      flex: 1,
      field: "grantAccess",
      align: "right",
      headerAlign: "right",
      renderCell: (item) => (
        <Switch
          checked={item?.row?.isActive && item?.row?.isVerified}
          onChange={async (value) => {
            const response = await accessBoardService.grantAccess({
              userPhoneNumber: item?.row?.phone,
              hasAccess: value.target.checked,
            });
            if (response.status !== 200) {
              toast.error("Failed to grant access");
            }
            toast.success(
              `Access ${value ? "granted" : "revoked"} successfully`
            );
            queryClient.invalidateQueries([QueryKeys.ACCESS_LIST]);
          }}
        />
      ),
    },
    currentUser.phone === "7016333333" && {
      headerName: "Delete User",
      flex: 1,
      field: "deleteClient",
      align: "right",
      headerAlign: "right",
      renderCell: (item) => (
        <IconButton
          color="error"
          onClick={(e) => {
            e.stopPropagation();
            setSelectedClientId(item?.row?.id);
            setConfirmationModal(true);
          }}
        >
          <MdDelete />
        </IconButton>
      ),
    },
  ];

  return (
    <DashboardLayout title="Client Board">
      <GKClientDataGrid
        columns={columns}
        rows={accessList?.data || []}
        loading={isRefetching || isLoading}
      />
      {confirmationModal && (
        <ConfirmDeleteModal
          open={confirmationModal}
          setOpen={setConfirmationModal}
          onDelete={() => deleteClient(selectedClientId)}
          title="Delete User"
          isDelete
        />
      )}
    </DashboardLayout>
  );
}

export default AdminAccessBoard;
