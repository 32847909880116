import { Button, Grid } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import CommonPagination from "components/CommanPagination";
import GKTable from "components/GKTable";
import useToast from "hooks/useToast";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { moneyCommaSeparator } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import CreateCompanyModal from "./Components/CreateCompanyModal";

const columns: GridColDef[] = [
  {
    headerName: "Company Name",
    field: "name",
    flex: 1,
    minWidth: 350,
    disableColumnMenu: true,
  },
  {
    headerName: "CMP",
    field: "cmp",
    flex: 1,
    type: "number",
    minWidth: 150,
    disableColumnMenu: true,
    valueGetter: (row) => moneyCommaSeparator(Number(row.row.cmp)) || 0,
  },
  {
    headerName: "BSE Code",
    field: "bseCode",
    flex: 1,
    type: "number",
    minWidth: 150,
    disableColumnMenu: true,
  },
  {
    headerName: "IsinCode",
    field: "isinCode",
    flex: 1,
    minWidth: 150,
    disableColumnMenu: true,
  },
  {
    headerName: "NSE Code",
    field: "nseCode",
    flex: 1,
    minWidth: 150,
    disableColumnMenu: true,
  },
  {
    headerName: "Type",
    field: "instType",
    flex: 1,
    minWidth: 150,
    disableColumnMenu: true,
  },
  {
    headerName: "Sub Type",
    field: "instSubType",
    flex: 1,
    minWidth: 150,
    disableColumnMenu: true,
  },
];

const CompanyMasterMainPage = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const {
    state: { commonFilter, currentUser },
  } = useAppContext();

  const isDisabled = currentUser?.phone === "9911223344";

  const [openModal, setOpenModal] = useState(false);

  const requestBody = {
    requireTotalCount: true,
    take: commonFilter?.companyMaster?.take,
    skip: commonFilter?.companyMaster?.skip,
    sort: JSON.stringify(
      commonFilter?.companyMaster?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: commonFilter?.companyMaster?.search || "",
    show_zero_holding: commonFilter?.companyMaster?.showNonZero,
    filter: JSON.stringify(commonFilter?.companyMaster?.customFilter),
  };

  const {
    data: companyList,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: [QueryKeys.COMPANY_NAMES, requestBody],
    queryFn: () =>
      axiosInstance.get("/admin-instrument/", { params: requestBody }),
    onError: (error: any) =>
      toast.show("error", error?.message || "Something went wrong"),
    staleTime: 24 * 60 * 60 * 1000,
  });

  const cusPagination = () => {
    return (
      <CommonPagination
        name="companyMaster"
        totalCount={companyList?.data.totalCount || 0}
      />
    );
  };

  return (
    <DashboardLayout title="Company Master">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <GKTable
            loading={isLoading}
            columns={columns}
            rows={companyList?.data?.data || []}
            onRowClick={(row) => {
              navigate(`/app/company-master/update/${row.row.gcode}`);
            }}
            name="companyMaster"
            CustomPagination={!isLoading && cusPagination}
            headerComponent={
              <Grid
                container
                gap={1}
                display={"flex"}
                justifyContent={"flex-end"}
              >
                <Button
                  variant="contained"
                  onClick={() => navigate("/app/company-master/unique-company")}
                >
                  Unique company
                </Button>
                <Button
                  variant="contained"
                  disabled={isDisabled}
                  onClick={() => setOpenModal(true)}
                >
                  Add Company
                </Button>
              </Grid>
            }
          />
        </Grid>
      </Grid>

      {openModal && (
        <CreateCompanyModal
          isModalOpen={openModal}
          setIsModalOpen={setOpenModal}
          refetch={refetch}
        />
      )}
    </DashboardLayout>
  );
};

export default CompanyMasterMainPage;
