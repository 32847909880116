import { GridColDef } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import CommonPagination from "components/CommanPagination";
import GKTable from "components/GKTable";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";

const EquityBasketTable = () => {
  const {
    state: { commonFilter },
  } = useAppContext();

  const requestBody = {
    requireTotalCount: true,
    take: commonFilter?.equityBasket?.take,
    skip: commonFilter?.equityBasket?.skip,
    sort: JSON.stringify(
      commonFilter?.equityBasket?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: commonFilter?.equityBasket?.search || "",
  };

  const { data, isFetching } = useQuery({
    queryKey: ["DASHBOARD_ADVISOR_TABLE", requestBody],
    queryFn: () =>
      axiosInstance.get(`/super_admin/model-portfolios/`, {
        params: requestBody,
      }),
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const columns: GridColDef[] = [
    {
      headerName: "Name",
      field: "name",
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      valueGetter: (row) => row?.row?.name || "-",
    },
    {
      headerName: "Advisor Name",
      field: "advisor",
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      valueGetter: (row) => row?.row?.advisor || "-",
    },
    {
      headerName: "Versions",
      field: "versionsCount",
      disableColumnMenu: true,
      flex: 1,
      minWidth: 200,
      type: "number",
      valueGetter: (row) => row?.row?.versionsCount || 0,
    },
    {
      headerName: "Mapped Portfolios",
      field: "mappedPortfoliosCount",
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      type: "number",
      valueGetter: (row) => row?.row?.mappedPortfoliosCount || 0,
    },
    {
      headerName: "Unique Item",
      field: "uniqueItemCount",
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      type: "number",
      valueGetter: (row) => row?.row?.uniqueItemCount || 0,
    },
    {
      headerName: "Total Orders",
      field: "ordersCount",
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      type: "number",
      valueGetter: (row) => row?.row?.ordersCount || 0,
    },
    {
      headerName: "Allocation",
      field: "allocationCount",
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      type: "number",
      valueGetter: (row) => row?.row?.allocationCount || 0,
    },
    {
      headerName: "Rebalance",
      field: "rebalanceCount",
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      type: "number",
      valueGetter: (row) => row?.row?.rebalanceCount || 0,
    },
    {
      headerName: "Exit",
      field: "exitCount",
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      type: "number",
      valueGetter: (row) => row?.row?.exitCount || 0,
    },
    {
      headerName: "Company Wise Exit",
      field: "cwexitCount",
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      type: "number",
      valueGetter: (row) => row?.row?.cwexitCount || 0,
    },
  ];

  const cusPagination = () => {
    return (
      <CommonPagination
        name="equityBasket"
        totalCount={data?.data.totalCount || 0}
      />
    );
  };

  return (
    <GKTable
      loading={isFetching}
      columns={columns}
      name="equityBasket"
      rows={
        data?.data?.data.map((dataItem: any, index: number) => {
          return {
            ...dataItem,
            id: index,
          };
        }) || []
      }
      CustomPagination={!isFetching && cusPagination}
    />
  );
};

export default EquityBasketTable;
