import { Button, Grid, IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useMutation, useQuery } from "@tanstack/react-query";
import ConfirmDeleteModal from "components/ConfirmDeleteModal";
import GKDataGrid from "components/GKDataGrid";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import toast from "react-hot-toast";
import { MdDelete } from "react-icons/md";
import { useParams } from "react-router-dom";

import axiosInstance from "utils/axiosInstance";
import { QueryKeys } from "utils/queryKey";
import BrokerMasterDetailsModal from "./BrokerMasterDetailsModal";

function DetailsPage() {
  // const navigate = useNavigate();
  const { id } = useParams();

  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [detailsData, setDetailsData] = useState({});
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [brokerTempleId, setBrokerTempleId] = useState<any>(0);

  const { data, isLoading, refetch } = useQuery({
    queryKey: [QueryKeys.BROKER_MASTER_DETAILS_LIST],
    queryFn: () => axiosInstance.get(`/broker-integration/?broker_id=${id}`),
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const { mutate: handleDeleteBrokerDetails } = useMutation({
    mutationKey: ["DELETE_BROKEN"],
    mutationFn: () =>
      axiosInstance.delete(`/broker-integration/${brokerTempleId}/delete/`),
    onSuccess: (response) => {
      refetch();
      toast.success(
        response?.data?.message || "Delete Broker Details Successfully"
      );
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const columns: GridColDef[] = [
    {
      headerName: "File Type",
      field: "fileType",
      flex: 1,
      minWidth: 100,
      disableColumnMenu: true,
    },
    {
      headerName: "Daily Bulk",
      field: "dailyBulk",
      flex: 1,
      minWidth: 100,
      disableColumnMenu: true,
    },
    {
      headerName: "Version",
      field: "version",
      flex: 1,
      minWidth: 50,
      disableColumnMenu: true,
    },
    {
      headerName: "Template",
      field: "template",
      flex: 1,
      minWidth: 250,
      disableColumnMenu: true,
      renderCell: (item) => (
        <Button
          href={item?.row?.template}
          target="_blank"
          onClick={(event) => {
            event?.stopPropagation();
          }}
        >
          View File
        </Button>
      ),
    },
    {
      headerName: "Actions",
      disableColumnMenu: true,
      headerAlign: "right",
      align: "right",
      flex: 1,
      minWidth: 80,
      field: "action",
      type: "action",
      renderCell: (item) => (
        <Grid container gap={1} justifyContent={"flex-end"}>
          <IconButton
            className="mr-2"
            color="primary"
            onClick={(event) => {
              event.stopPropagation();
              setBrokerTempleId(item.id);
              setConfirmationModal(true);
            }}
          >
            <MdDelete size={20} />
          </IconButton>
        </Grid>
      ),
    },
  ];

  return (
    <DashboardLayout
      title="Broker Master Details"
      breadcrumbs={[
        {
          name: "Broker Master",
          path: "/app/broker-master",
        },
        {
          name: "Details",
          path: "",
        },
      ]}
      // toolbar={
      //   <Button onClick={() => navigate("/app/broker-master")}>
      //     Back &nbsp; <TbArrowBackUp />
      //   </Button>
      // }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              setDetailsData({});
              setOpenDetailsModal(true);
            }}
          >
            Create Broker Master
          </Button>
        </Grid>
        <Grid item xs={12}>
          <GKDataGrid
            loading={isLoading}
            columns={columns}
            rows={data?.data?.data || []}
            onRowClick={(row) => {
              setDetailsData(row.row);
              setOpenDetailsModal(true);
            }}
          />
        </Grid>
      </Grid>

      {openDetailsModal && (
        <BrokerMasterDetailsModal
          isModalOpen={openDetailsModal}
          setIsModalOpen={setOpenDetailsModal}
          refetch={refetch}
          detailsData={detailsData}
        />
      )}

      {confirmationModal && (
        <ConfirmDeleteModal
          open={confirmationModal}
          setOpen={setConfirmationModal}
          onDelete={() => handleDeleteBrokerDetails()}
          title={"Broker Master"}
          isDelete
        />
      )}
    </DashboardLayout>
  );
}

export default DetailsPage;
