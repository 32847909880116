import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Switch,
  Typography,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { brokerSupport } from "utils/preferences";
import { theme } from "utils/Theme";

interface BrokerValues {
  [key: string]: boolean;
  adityaBirlaSupport: boolean;
  pmsSupport: boolean;
}

interface Props {
  data: any;
  isFetched: boolean;
  refetch: () => void;
}

const BrokerPreference = (props: Props) => {
  const { data, isFetched, refetch } = props;

  const { id } = useParams();

  const [expanded, setExpanded] = useState<number>(-1);

  const { mutate } = useMutation({
    mutationKey: ["UPDATE_BROKER"],
    mutationFn: (data: any) =>
      axiosInstance.patch(
        `/super_admin/${id}/broker_integration_status/`,
        data
      ),
    onSuccess: (response) => {
      refetch();
      toast.success(response?.data?.message || "Broker Update Successfully");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const formik = useFormik<BrokerValues>({
    enableReinitialize: isFetched,
    initialValues: {
      adityaBirlaSupport: data?.data?.data?.adityaBirlaSupport || false,
      pmsSupport: data?.data?.data?.pmsSupport || false,
    },
    onSubmit: (values: any) => {
      mutate(values);
    },
  });

  return (
    <form id="broke-preferences-form" onSubmit={formik.handleSubmit}>
      {brokerSupport.map((dataItem, index) => {
        return (
          <Accordion
            key={index}
            disableGutters
            onChange={() => {
              setExpanded(index === expanded ? -1 : index);
            }}
            expanded={index === expanded}
            style={{
              border: `solid 1px ${theme.palette.dark?.main}44`,
              borderRadius: "8px",
              boxShadow: "none",
              background: "none",
            }}
          >
            <AccordionSummary
              expandIcon={<GridExpandMoreIcon />}
              aria-controls={`panel${index + 1}-content`}
              id={`panel${index + 1}-header`}
              sx={{
                "& .MuiAccordionSummary-content": {
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                },
              }}
            >
              <Typography
                fontSize={16}
                fontWeight={600}
                textTransform={"none"}
                color={theme.palette.primary.main}
              >
                {dataItem.mainHeading}
              </Typography>
              {expanded === 0 && (
                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  form="broke-preferences-form"
                  onClick={(e) => e.stopPropagation()}
                >
                  Save
                </Button>
              )}
            </AccordionSummary>
            <Divider />
            {dataItem.details.map((data, subIndex) => {
              return (
                <AccordionDetails
                  key={subIndex}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Switch
                    onChange={(event) => {
                      formik.setFieldValue(data.key, event.target.checked);
                    }}
                    checked={formik.values[data.key]}
                  />

                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    textTransform={"none"}
                    color={theme.palette.dark?.main}
                  >
                    {data.heading}
                  </Typography>
                </AccordionDetails>
              );
            })}
          </Accordion>
        );
      })}
    </form>
  );
};

export default BrokerPreference;
