import { GridColDef } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import CommonPagination from "components/CommanPagination";
import GKTable from "components/GKTable";
import useToast from "hooks/useToast";
import DashboardLayout from "layouts/DashboardLayout";
import { useNavigate } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { QueryKeys } from "utils/queryKey";

const columns: GridColDef[] = [
  {
    headerName: "Company Name",
    field: "name",
    flex: 1,
    minWidth: 350,
    disableColumnMenu: true,
  },
  {
    headerName: "BSE Code",
    field: "bseCode",
    flex: 1,
    type: "number",
    minWidth: 150,
    disableColumnMenu: true,
  },
  {
    headerName: "IsinCode",
    field: "isinCode",
    flex: 1,
    minWidth: 150,
    disableColumnMenu: true,
  },
  {
    headerName: "NSE Code",
    field: "nseCode",
    flex: 1,
    minWidth: 150,
    disableColumnMenu: true,
  },
];

const UniqueCompany = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const {
    state: { commonFilter },
  } = useAppContext();

  const requestBody = {
    requireTotalCount: true,
    take: commonFilter?.uniqueCompany?.take,
    skip: commonFilter?.uniqueCompany?.skip,
    sort: JSON.stringify(
      commonFilter?.uniqueCompany?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: commonFilter?.uniqueCompany?.search || "",
    show_zero_holding: commonFilter?.uniqueCompany?.showNonZero,
    filter: JSON.stringify(commonFilter?.uniqueCompany?.customFilter),
  };

  const {
    data: companyList,
    isFetching,
    isLoading,
  } = useQuery({
    queryKey: [QueryKeys.UNIQUE_COMPANY, requestBody],
    queryFn: async () =>
      axiosInstance.get("/all-unique-company/", { params: requestBody }),
    onError: (error: any) =>
      toast.show("error", error?.message || "Something went wrong"),
    staleTime: 24 * 60 * 60 * 1000,
  });

  const cusPagination = () => {
    return (
      <CommonPagination
        name="uniqueCompany"
        totalCount={companyList?.data.totalCount || 0}
      />
    );
  };

  return (
    <DashboardLayout
      title="Company Master"
      breadcrumbs={[
        {
          name: "Company Master",
          path: "/app/company-master",
        },
        {
          name: "Unique Company",
          path: "",
        },
      ]}
      // toolbar={
      //   <Button onClick={() => navigate(-1)}>
      //     Back &nbsp; <TbArrowBackUp />
      //   </Button>
      // }
    >
      <GKTable
        loading={isFetching}
        columns={columns}
        rows={companyList?.data?.data || []}
        onRowClick={(row) => {
          navigate(`/app/company-master/update/${row.row.gcode}`);
        }}
        CustomPagination={!isLoading && cusPagination}
        name="uniqueCompany"
      />
    </DashboardLayout>
  );
};

export default UniqueCompany;
