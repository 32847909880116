import { GridColDef } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import CommonPagination from "components/CommanPagination";
import GKTable from "components/GKTable";
import StatusField from "components/StatusField";
import toast from "react-hot-toast";

import axiosInstance from "utils/axiosInstance";

const ClientsTable = () => {
  const {
    state: { commonFilter },
  } = useAppContext();

  const requestBody = {
    requireTotalCount: true,
    take: commonFilter?.client?.take,
    skip: commonFilter?.client?.skip,
    sort: JSON.stringify(
      commonFilter?.client?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: commonFilter?.client?.search || "",
  };

  const { data, isFetching } = useQuery({
    queryKey: ["DASHBOARD_CLIENT_TABLE", requestBody],
    queryFn: () =>
      axiosInstance.get(`/super_admin/clients/`, {
        params: requestBody,
      }),
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const columns: GridColDef[] = [
    {
      headerName: "Name",
      field: "name",
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      valueGetter: (row) => row?.row?.name || "-",
    },
    {
      headerName: "Phone Number",
      field: "phone",
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      type: "number",
      valueGetter: (row) => row?.row?.phone || "-",
    },
    {
      headerName: "Company Name",
      field: "companyName",
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      valueGetter: (row) => row?.row?.companyName || "-",
    },
    {
      headerName: "Advisor",
      field: "advisor",
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      valueGetter: (row) => row?.row?.advisor || "-",
    },
    // {
    //   headerName: 'Agreement Status',
    //   field: 'agreementStatus',
    //   disableColumnMenu: true,
    //   flex: 1,
    //   minWidth: 150,
    //   valueGetter: (row) => row?.row?.agreementStatus || '-',
    //   renderCell: (props) => {
    //     return <StatusField statusString={`${props?.row?.agreementStatus}`} />
    //   },
    // },
    {
      headerName: "Risk Score",
      field: "riskScore",
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      valueGetter: (row) => row?.row?.riskScore || "-",
      renderCell: (props) => {
        return <StatusField statusString={`${props?.row?.riskScore}`} />;
      },
    },
    {
      headerName: "KYC Status",
      field: "kycStatus",
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      valueGetter: (row) => row?.row?.kycStatus || "-",
      renderCell: (props) => {
        return <StatusField statusString={`${props?.row?.kycStatus}`} />;
      },
    },
    {
      headerName: "Created on",
      field: "createdOn",
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      // valueFormatter: (params) => dayjs(params?.value).format("DD MMM YYYY"),
      valueGetter: (row) => row?.row?.createdOn || "N/A",
    },
  ];

  const cusPagination = () => {
    return (
      <CommonPagination name="client" totalCount={data?.data.totalCount || 0} />
    );
  };

  return (
    <GKTable
      loading={isFetching}
      columns={columns}
      name="client"
      rows={data?.data?.data || []}
      CustomPagination={!isFetching && cusPagination}
    />
  );
};

export default ClientsTable;
