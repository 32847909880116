import { Button, Grid, IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import CommonPagination from "components/CommanPagination";
import GKTable from "components/GKTable";
import { useState } from "react";
import toast from "react-hot-toast";
import { BiRefresh } from "react-icons/bi";
import { CgMathPlus } from "react-icons/cg";
import { MdDelete, MdOutlineSettings } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { moneyCommaSeparatorPoint } from "utils/helpers";
import CreateAdvisorModal from "./CreateAdvisorModal";

const AdvisorTable = () => {
  const navigate = useNavigate();

  const {
    state: { commonFilter },
  } = useAppContext();

  const [advisorCreateModal, setAdvisorCreateModal] = useState(false);

  const requestBody = {
    requireTotalCount: true,
    take: commonFilter?.advisor?.take,
    skip: commonFilter?.advisor?.skip,
    sort: JSON.stringify(
      commonFilter?.advisor?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: commonFilter?.advisor?.search || "",
  };

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["DASHBOARD_ADVISOR_TABLE", requestBody],
    queryFn: () =>
      axiosInstance.get(`/super_admin/advisors/`, {
        params: requestBody,
      }),
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const { mutate: refreshAccount } = useMutation({
    mutationKey: ["REFRESH_ACCOUNT"],
    mutationFn: (id: number | string) =>
      axiosInstance.get(`/super_admin/${id}/refresh_account/`),
    onSuccess: (response) => {
      toast.success(response?.data?.message || "Refresh Account Successfully");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const { mutate: deleteClient } = useMutation({
    mutationKey: ["DELETE_CLIENT"],
    mutationFn: (data) =>
      axiosInstance.delete(`/super_admin/${data}/delete-advisor/`),
    onSuccess: () => {
      refetch();
    },
  });

  const columns: GridColDef[] = [
    {
      headerName: "Company Name",
      field: "companyName",
      disableColumnMenu: true,
      flex: 1,
      minWidth: 200,
      valueGetter: (row) => row?.row?.companyName || "-",
    },
    {
      headerName: "Advisor Name",
      field: "name",
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      valueGetter: (row) => row?.row?.name || "-",
    },
    {
      headerName: "Phone Number",
      field: "phone",
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      type: "number",
      valueGetter: (row) => row?.row?.phone || "-",
    },
    {
      headerName: "Total Aum",
      field: "totalAum",
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      type: "number",
      valueGetter: (row) => row?.row?.totalAum || 0,
      valueFormatter: (params) => moneyCommaSeparatorPoint(params.value),
    },
    {
      headerName: "Clients",
      field: "clients",
      disableColumnMenu: true,
      flex: 1,
      minWidth: 100,
      type: "number",
      valueGetter: (row) => row?.row?.clients || 0,
    },
    {
      headerName: "Portfolios",
      field: "portfolios",
      disableColumnMenu: true,
      flex: 1,
      minWidth: 100,
      type: "number",
      valueGetter: (row) => row?.row?.portfolios || 0,
    },
    {
      headerName: "Created on",
      field: "createdOn",
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      // valueFormatter: (params) => dayjs(params?.value).format("DD MMM YYYY"),
      valueGetter: (row) => row?.row?.createdOn || "N/A",
    },
    {
      headerName: "Actions",
      field: "action",
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Grid container gap={2}>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                refreshAccount(params.id);
              }}
            >
              <BiRefresh />
            </IconButton>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/app/preference/${params.row.phone}`);
              }}
            >
              <MdOutlineSettings />
            </IconButton>
            <IconButton
              color="error"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                deleteClient(params?.row?.id);
              }}
            >
              <MdDelete />
            </IconButton>
          </Grid>
        );
      },
    },
    // {
    //   headerName: "Delete Client",
    //   flex: 1,
    //   field: "deleteClient",
    //   align: "right",
    //   headerAlign: "right",
    //   renderCell: (item) => (
    //     <IconButton
    //       color="error"
    //       onClick={(e) => {
    //         e.stopPropagation();
    //         e.preventDefault();
    //         deleteClient(item?.row?.id);
    //       }}
    //     >
    //       <MdDelete />
    //     </IconButton>
    //   ),
    // },
  ];

  const cusPagination = () => {
    return (
      <CommonPagination
        name="advisor"
        totalCount={data?.data.totalCount || 0}
      />
    );
  };

  return (
    <>
      <GKTable
        loading={isFetching}
        columns={columns}
        rows={data?.data?.data || []}
        name="advisor"
        CustomPagination={!isFetching && cusPagination}
        onRowClick={(row) => navigate(`/app/advisor/${row.id}/details`)}
        headerComponent={
          <Button
            onClick={() => setAdvisorCreateModal(true)}
            variant="contained"
            size="medium"
          >
            <CgMathPlus size={18} /> Create Advisor
          </Button>
        }
      />

      {advisorCreateModal && (
        <CreateAdvisorModal
          open={advisorCreateModal}
          setOpen={setAdvisorCreateModal}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default AdvisorTable;
