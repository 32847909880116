import { GridColDef } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import CommonPagination from "components/CommanPagination";
import GKTable from "components/GKTable";
import toast from "react-hot-toast";

import axiosInstance from "utils/axiosInstance";

const EmployeeTable = () => {
  const {
    state: { commonFilter },
  } = useAppContext();

  const requestBody = {
    take: commonFilter?.employee?.take,
    skip: commonFilter?.employee?.skip,
    search: commonFilter?.employee?.search || "",
    sort: JSON.stringify(
      commonFilter?.employee?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
  };

  const { data, isFetching } = useQuery({
    queryKey: ["DASHBOARD_EMPLOYEE_TABLE", requestBody],
    queryFn: () =>
      axiosInstance.get(`/super_admin/employees/`, {
        params: requestBody,
      }),
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const columns: GridColDef[] = [
    {
      headerName: "Employee Name",
      field: "name",
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      valueGetter: (row) => row?.row?.name || "-",
    },
    {
      headerName: "Company Name",
      field: "companyName",
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      valueGetter: (row) => row?.row?.companyName || "-",
    },
    {
      headerName: "Phone Number",
      field: "phone",
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      type: "number",
      valueGetter: (row) => row?.row?.phone || "-",
    },
    {
      headerName: "Created on",
      field: "createdOn",
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      // valueFormatter: (params) => dayjs(params?.value).format("DD MMM YYYY"),
      valueGetter: (row) => row?.row?.createdOn || "N/A",
    },
  ];

  const cusPagination = () => {
    return (
      <CommonPagination
        name="employee"
        totalCount={data?.data.totalCount || 0}
      />
    );
  };

  return (
    <GKTable
      loading={isFetching}
      columns={columns}
      name="employee"
      rows={data?.data?.data || []}
      CustomPagination={!isFetching && cusPagination}
    />
  );
};

export default EmployeeTable;
