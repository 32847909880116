import { Button, Grid } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import CommonPagination from "components/CommanPagination";
import GKTable from "components/GKTable";
import dayjs from "dayjs";
import { useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import HistoricalModal from "./HistoricalModal";

function HistoricalPrices() {
  const { id } = useParams();

  const {
    state: { commonFilter, currentUser },
  } = useAppContext();

  const isDisabled = currentUser?.phone === "9911223344";

  const [isOpenModal, setIsOpenModal] = useState(false);

  const [type, setType] = useState("");

  const requestBody = {
    requireTotalCount: true,
    take: commonFilter?.historicalPrice?.take,
    skip: commonFilter?.historicalPrice?.skip,
    sort: JSON.stringify(
      commonFilter?.historicalPrice?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: commonFilter?.historicalPrice?.search || "",
  };

  const {
    data: historicalPricesData,
    isFetching: historicalPriceFetching,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["ADMIN_INSTRUMENT_HMP", requestBody],
    queryFn: async () =>
      axiosInstance.get(`/admin-instrument-hmp/${id}/`, {
        params: requestBody,
      }),
  });

  const cusPagination = () => {
    return (
      <CommonPagination
        name="historicalPrice"
        totalCount={historicalPricesData?.data.totalCount || 0}
      />
    );
  };

  const historicalPriceColumns: GridColDef[] = [
    {
      headerName: "Timestamp",
      field: "timestamp",
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (row) => dayjs(row?.row?.timestamp).format("DD/MM/YYYY"),
    },
    {
      headerName: "Adjusted Price",
      field: "adjustedPrice",
      type: "number",
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (row) => parseFloat(row?.row?.adjustedPrice).toFixed(2),
    },
    {
      headerName: "Unadjusted Price",
      field: "unadjustedPrice",
      type: "number",
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (row) => parseFloat(row?.row?.unadjustedPrice).toFixed(2),
    },
  ];

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
        <Button
          variant="outlined"
          disabled={isDisabled}
          onClick={() => {
            setType("HMP");
            setIsOpenModal(true);
          }}
        >
          Add HMP
        </Button>{" "}
        &nbsp;
        <Button
          variant="outlined"
          disabled={isDisabled}
          onClick={() => {
            setType("CMP");
            setIsOpenModal(true);
          }}
        >
          Update CMP
        </Button>
      </Grid>
      <Grid item xs={12}>
        <GKTable
          loading={historicalPriceFetching}
          columns={historicalPriceColumns}
          rows={historicalPricesData?.data?.data || []}
          showFilters={false}
          name="historicalPrice"
          CustomPagination={!isLoading && cusPagination}
          searchPlaceholder="YYYY-MM-DD"
        />
      </Grid>

      {isOpenModal && (
        <HistoricalModal
          open={isOpenModal}
          setOpen={setIsOpenModal}
          type={type}
          refetch={refetch}
        />
      )}
    </Grid>
  );
}

export default HistoricalPrices;
