import { Box, Card, Grid, Skeleton, Typography } from "@mui/material";
import { theme } from "utils/Theme";
import { moneyCommaSeparatorPoint } from "utils/helpers";

interface Props {
  data: {
    aum: number;
    companies: number;
    branches: number;
    advisors: number;
    employees: number;
    partners: number;
    clients: number;
    families: number;
    portfolios: number;
    portfolioGroups: number;
    transactions: number;
    cashEntries: number;
    transactionUploads: number;
    contractNotes: number;
    reports: number;
    leads: number;
    newUsersToday: number;
    loginToday: number;
    modelPortfolioMappedPortfolios: number;
    modelPortfolioOrders: number;
    modelPortfolioUniqueStocks: number;
    modelPortfolioVersions: number;
    modelPortfolios: number;
  };
  isLoading: boolean;
}

const DashboardCard = (props: Props) => {
  const { data, isLoading } = props;

  const dashboardDetails = [
    {
      name: "Advisors",
      value: data?.advisors || (isLoading ? <Skeleton /> : 0),
    },
    {
      name: "Total Aum",
      value:
        moneyCommaSeparatorPoint(data?.aum) || (isLoading ? <Skeleton /> : 0),
    },
    {
      name: "Companies",
      value: data?.companies || (isLoading ? <Skeleton /> : 0),
    },
    {
      name: "Branches",
      value: data?.branches || (isLoading ? <Skeleton /> : 0),
    },
    {
      name: "Employees",
      value: data?.employees || (isLoading ? <Skeleton /> : 0),
    },
    {
      name: "Partners",
      value: data?.partners || (isLoading ? <Skeleton /> : 0),
    },
    {
      name: "Clients",
      value: data?.clients || (isLoading ? <Skeleton /> : 0),
    },
    {
      name: "Families",
      value: data?.families || (isLoading ? <Skeleton /> : 0),
    },
    {
      name: "Portfolios",
      value: data?.portfolios || (isLoading ? <Skeleton /> : 0),
    },
    {
      name: "Portfolio Groups",
      value: data?.portfolioGroups || (isLoading ? <Skeleton /> : 0),
    },
    {
      name: "Transactions",
      value: data?.transactions || (isLoading ? <Skeleton /> : 0),
    },
    {
      name: "Cash Entries",
      value: data?.cashEntries || (isLoading ? <Skeleton /> : 0),
    },
    {
      name: "Transaction Uploads",
      value: data?.transactionUploads || (isLoading ? <Skeleton /> : 0),
    },
    {
      name: "Contract Notes",
      value: data?.contractNotes || (isLoading ? <Skeleton /> : 0),
    },
    {
      name: "Reports Generated",
      value: data?.reports || (isLoading ? <Skeleton /> : 0),
    },
    {
      name: "Leads",
      value: data?.leads || (isLoading ? <Skeleton /> : 0),
    },
    {
      name: "Daily Active Users",
      value: data?.loginToday || (isLoading ? <Skeleton /> : 0),
    },
    {
      name: "New Users Today",
      value: data?.newUsersToday || (isLoading ? <Skeleton /> : 0),
    },
    {
      name: "Equity Basket Mapped Portfolios",
      value:
        data?.modelPortfolioMappedPortfolios || (isLoading ? <Skeleton /> : 0),
    },
    {
      name: "Equity Basket Orders",
      value: data?.modelPortfolioOrders || (isLoading ? <Skeleton /> : 0),
    },
    {
      name: "Equity Basket Unique Stocks",
      value: data?.modelPortfolioUniqueStocks || (isLoading ? <Skeleton /> : 0),
    },
    {
      name: "Equity Basket Versions",
      value: data?.modelPortfolioVersions || (isLoading ? <Skeleton /> : 0),
    },
    {
      name: "Equity Basket",
      value: data?.modelPortfolios || (isLoading ? <Skeleton /> : 0),
    },
  ];

  return (
    <Box padding={3}>
      <Grid container spacing={2.5}>
        {dashboardDetails.map((item, index) => (
          <Grid item xs={4} key={index}>
            <Card
              sx={{
                padding: 2,
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                borderRadius: 2,
                textAlign: "center",
              }}
            >
              <Typography
                fontSize={16}
                fontWeight={500}
                color={theme.palette.text.secondary}
              >
                {item.name}
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={700}
                color={theme.palette.primary.main}
                marginTop={1}
              >
                {item.value}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default DashboardCard;
