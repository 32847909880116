import { Button, Grid, IconButton, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useMutation, useQuery } from "@tanstack/react-query";
import ConfirmDeleteModal from "components/ConfirmDeleteModal";
import GKClientDataGrid from "components/GKClientDataGrid";
import StatusField from "components/StatusField";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import toast from "react-hot-toast";
import { MdDelete } from "react-icons/md";
import { RiEdit2Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { QueryKeys } from "utils/queryKey";
import CreateBrokerModal from "./CreateBrokerModal";

const BrokerMaster = () => {
  const navigate = useNavigate();

  const [openBrokerModal, setOpenBrokerModal] = useState(false);
  const [brokerData, setBrokerData] = useState({});
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [brokerMasterId, setBrokerMasterId] = useState<any>(0);

  const { data, isLoading, refetch } = useQuery({
    queryKey: [QueryKeys.BROKER_MASTER_LIST],
    queryFn: () => axiosInstance.get("/broker/"),
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const { mutate: handleDeleteBroker } = useMutation({
    mutationKey: ["DELETE_BROKEN"],
    mutationFn: () => axiosInstance.delete(`/broker/${brokerMasterId}/delete/`),
    onSuccess: (response) => {
      refetch();
      toast.success(response?.data?.message || "Delete Broker Successfully");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const brokerColumns: GridColDef[] = [
    {
      headerName: "Name",
      field: "name",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 150,
    },
    {
      headerName: "Status",
      field: "status",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 150,
      renderCell: (params) => (
        <StatusField
          statusString={params?.row?.status ? "Active" : "Inactive"}
        />
      ),
    },
    {
      headerName: "Integration Status",
      field: "integrationStatus",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 150,
      renderCell: (params) => (
        <StatusField
          statusString={params?.row?.integrationStatus ? "Active" : "Inactive"}
        />
      ),
    },
    {
      headerName: "Meta",
      field: "meta",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 150,
      renderCell: (params) => (
        <Typography>
          {JSON.parse(params?.row?.meta.replace(/'/g, '"')).join(", ")}
        </Typography>
      ),
    },
    {
      headerName: "Actions",
      disableColumnMenu: true,
      headerAlign: "right",
      align: "right",
      flex: 1,
      minWidth: 80,
      field: "action",
      type: "action",
      renderCell: (item) => (
        <Grid container gap={1} justifyContent={"flex-end"}>
          <IconButton
            color="primary"
            onClick={(event) => {
              event.stopPropagation();
              navigate(`/app/broker-master/${item.id}`);
            }}
          >
            <RiEdit2Fill size={20} />
          </IconButton>
          <IconButton
            color="error"
            onClick={(event) => {
              event.stopPropagation();
              setBrokerMasterId(item?.id);
              setConfirmationModal(true);
            }}
          >
            <MdDelete size={20} />
          </IconButton>
        </Grid>
      ),
    },
  ];

  return (
    <DashboardLayout
      title="Broker Master"
      breadcrumbs={[
        {
          name: "Broker Master",
          path: "/app/broker-master",
        },
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <GKClientDataGrid
            headerComponent={
              <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setBrokerData({});
                    setOpenBrokerModal(true);
                  }}
                >
                  Create Broker Master
                </Button>
              </Grid>
            }
            showFiltersButton={false}
            loading={isLoading}
            columns={brokerColumns}
            rows={data?.data?.data || []}
            onRowClick={(row) => {
              setBrokerData(row?.row);
              setOpenBrokerModal(true);
            }}
          />
        </Grid>
      </Grid>

      {openBrokerModal && (
        <CreateBrokerModal
          isModalOpen={openBrokerModal}
          setIsModalOpen={setOpenBrokerModal}
          refetch={refetch}
          brokerData={brokerData}
        />
      )}

      {confirmationModal && (
        <ConfirmDeleteModal
          open={confirmationModal}
          setOpen={setConfirmationModal}
          onDelete={() => handleDeleteBroker()}
          title={"Broker Master"}
          isDelete
        />
      )}
    </DashboardLayout>
  );
};

export default BrokerMaster;
